<template>
	<div v-if="isRecapcha">
		<h2
			class="auth-form__captcha-title"
			style="margin-bottom: 8px;"
		>
			Подтвердите, что вы не робот
		</h2>
		<vue-recaptcha
			:sitekey="vueAppCaptchaKey"
			@verify="onRecatchaSuccess"
		/>
	</div>
	
	<div v-else class="second-step">
		<h2 class="auth-form__title">Введите код из смс</h2>
		<h3
			class="auth-form__subtitle"
			style="margin-top: 24px"
		>
			Мы отправили смс с кодом на номер<br>{{ phoneNumber }}
		</h3>
		<SmsCodeInput
			class="second-step__sms-code-input"
			@handleCodeEntered="handleCodeEntered"
			@clearError="error = ''"
			:error="error"
		/>
		<div
			v-if="timer !== 0"
			class="second-step__timer-wrapper"
		>
			Запросить новый код через
			<span class="second-step__timer">
				{{ timer + ' сек' }}
			</span>
		</div>
		<UiButton
			v-else
			@click="handleStartTimer"
			variant="secondary"
			style="margin-top: 32px"
		>
			Получить код
		</UiButton>
		<UiButton
			@click.prevent="setAuthStep(1)"
			variant="text"
			size="small"
			:style="{marginTop: timer !== 0 ? '86px' : '70px'}"
		>
			<ArrowBackIcon class="step-arrow__icon"/>
			Изменить номер
		</UiButton>
	</div>
</template>
<script>
import {UiButton, SmsCodeInput} from "ui-kit";
import ArrowBackIcon from "icons/24/arrow-back.svg?inline";
import {mapActions, mapMutations} from "vuex";
import {notification} from "@/utils";
import {useVkRetargeting, useYandexMetrika, useTopMailCounter} from "@/composables";
import VueRecaptcha from 'vue-recaptcha';

export default {
	name: "SecondStep",
	components: {UiButton, SmsCodeInput, ArrowBackIcon, VueRecaptcha},
	setup()
	{
		const {yandexMetrikaGoal} = useYandexMetrika();
		const {vkRetargetingCompleteRegistration} = useVkRetargeting();
		const {topMailCounterCompleteRegistration} = useTopMailCounter();

		return {yandexMetrikaGoal, vkRetargetingCompleteRegistration, topMailCounterCompleteRegistration};
	},
	props: {
		phoneNumber: {
			type: String,
			required: true
		}
	},
	data()
	{
		return {
			timer: 59,
			error: "",
			isRecapcha: false,
			numberOfAttempts: 0,
			vueAppCaptchaKey: process.env.VUE_APP_CAPTCHA_KEY,
		};
	},
	methods: {
		...mapActions({
			loginBySmsCode: "loginBySmsCode",
			getCart: "getCart",
		}),
		...mapMutations({
			setUser: "setUser",
			setAuthFormPopup: "setAuthFormPopup",
			setAuthStep: "setAuthStep",
		}),
		handleStartTimer()
		{
			this.timer = 59;

			const interval = setInterval(() =>
			{
				if (this.timer === 1)
				{
					clearInterval(interval)
					return this.timer = 0;
				}

				this.timer--;
			}, 1000);
		},
		async handleCodeEntered(code)
		{
			this.numberOfAttempts += 1
			
			try
			{
				const params = {
					phone: this.phoneNumber,
					code
				};

				const {data} = await this.loginBySmsCode(params);

				if (!data.success && this.numberOfAttempts === 3)
				{
					this.numberOfAttempts = 0
					this.isRecapcha = true
					return
				}

				if (!data.success && data.msg) return this.error = data.msg;

				if (!data.success) return notification({title: "Неизвестная ошибка", type: "error"});

				this.setUser(data.user);

				await this.getCart();

				this.$socket.init();

				await this.handleUserRegistration(data?.isNewUser, data.user.ID);
			} catch (e)
			{
				throw new Error(e);
			}
		},
		onRecatchaSuccess(code)
		{
			this.isRecapcha = false
		},
		async handleUserRegistration(isNewUser, userId)
		{
			await this.yandexMetrikaGoal(45209871, "log_in", userId);

			if (isNewUser)
			{
				this.vkRetargetingCompleteRegistration();
				this.topMailCounterCompleteRegistration();
				return this.setAuthStep(3);
			}

			this.setAuthFormPopup(false);

			const userAgent = window.navigator.userAgent.match(/mobileApp/i);
			if (userAgent) return window.location.href = '/application/personal/';

			window.location.reload();
		}
	},
	mounted()
	{
		this.handleStartTimer();
	}
}
</script>
<style lang="scss">
.second-step
{
	display: flex;
	flex-direction: column;
	width: 100%;
}

.second-step__timer-wrapper
{
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 4px;

	margin-top: 48px;

	font-family: $mainFontNew;
	color: $textColor;
	font-weight: 400;
	font-size: 14px;
	line-height: 171%;
}

.second-step__timer
{
	font-family: $mainFontBoldNew;
	color: $green;
	font-weight: 700;
	font-size: 14px;
	line-height: 171%;
}

.second-step__sms-code-input
{
	margin-top: 32px;
	align-items: center;
	text-align: left;
}
</style>